import React, { useState, useEffect, useCallback, useMemo, Component } from "react";
import { FaTimes } from "react-icons/fa";
import WaxIcon from "assets/images/icons/waxp.png";
import Modal from "components/shared/Modal";
import { useError } from 'ErrorProvider';
import { useSound } from 'SoundContext';
import soundFile from 'assets/sound/start_battle.mp3'

import { useDispatch, useSelector } from "react-redux";
import { anchorChallenge, refreshAll} from "BlockchainInteractionWax.js"

const ChallengePopup = ({ isOpen, setIsOpen, ual, opponent }) => {
  const [hcc, setHCC] = useState("0");
  const [hdc, setHDC] = useState("0");
  const [htn, setHTN] = useState("0");
  const [wax, setWax] = useState("1");
  const [usdc, setUSDC] = useState("0.1");

  const { isMuted } = useSound();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  const fetchData = useCallback(async () => {
    if (ual.activeUser && store) {

    }
  }, [ual, store]); 

  useEffect(() => {
    fetchData();
  }, [fetchData, store]);

  async function handleChallengeClick()
  {
    const hccNumber = Number(hcc);
    const hdcNumber = Number(hdc);
    const htnNumber = Number(htn);
    const waxNumber = Number(wax);
    const usdcNumber = Number(usdc);
    let amounts = []
    amounts.push(hccNumber)
    amounts.push(hdcNumber)
    amounts.push(htnNumber)
    amounts.push(waxNumber)
    amounts.push(usdcNumber)

    await anchorChallenge(ual, opponent, amounts, setError)
    if (!isMuted) {
      const audio = new Audio(soundFile);
      audio.play().catch((error) => {
        console.error('Audio playback failed:', error);
      });
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch)
    setIsOpen(false);    
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
      <div
        className="w-full rounded-xl py-4 px-6 font-Montserrat py-4 px-6"
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <form>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-white">
              Bet amount
            </h2>
            <FaTimes
              className="block text-2xl text-white hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="text-center text-white"></div>
          <div>
            <div className="grid grid-cols-2 gap-4 justify-center">
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Clean Cash:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={hcc}
                  onChange={(event) => setHCC(event.target.value)}
                  placeholder="Enter how much do add to stat"
                  step="1"
                  min="1"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Dirty Cash:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={hdc}
                  onChange={(event) => setHDC(event.target.value)}
                  placeholder="Enter how much do add to stat"
                  step="1"
                  min="1"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Hustler Token:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={htn}
                  onChange={(event) => setHTN(event.target.value)}
                  placeholder="Enter how much do add to stat"
                  step="1"
                  min="1"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Wax:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={wax}
                  onChange={(event) => setWax(event.target.value)}
                  placeholder="Enter how much do add to stat"
                  step="1"
                  min="1"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">USDC:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={usdc}
                  onChange={(event) => setUSDC(event.target.value)}
                  placeholder="Enter how much do add to stat"
                  step="0.1"
                  min="0.1"
                />
              </div>
            </div>
          </div>
        </div>

          <div className="mt-5 flex justify-center">
          <div className='buttonContainer'>
            <button className="w-40 py-1 px-3"
            onClick={(e) => {e.preventDefault(); handleChallengeClick()}}>Challenge</button>
            /</div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ChallengePopup;
