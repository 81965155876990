import React, { useEffect, useState, useCallback } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import USDCIcon from "assets/images/icons/usdc.png";
import CCIcon from "assets/images/icons/clean-cash2.png"
import DCIcon from "assets/images/icons/dirty-cash-token.png"
import HTIcon from "assets/images/icons/hustlers-token.png"

import AddUSDCPopup from "../Character/AddUSDCPopup"
import WithdrawUsdcPopup from "../Character/WithdrawUsdcPopup"
import ChallengePopup  from "./ChallengePopup";
import { useError } from 'ErrorProvider';
import { useSound } from 'SoundContext';
import soundFile from 'assets/sound/boo.mp3'

import {refreshAll, anchorGetUsdcFromWallet, anchorGetUsdcFromPvp, anchorGetPvPOpponents, anchorGetChallengesInitiated, anchorCancelPvpChallenge, anchorGetAllBattleStats} from "BlockchainInteractionWax";

const MiddleContent = ({ ual }) => {

const [usdcWallet, setUsdcWallet] = useState(0);
const [usdcAmount, setUsdcAmount] = useState(0);
const [isOpenAddUSDC, setIsOpenAddUSDC] = useState(false);
const [isOpenChallenge, setIsOpenChallenge] = useState(false);
const [isOpenWithdrawUSDC, setIsOpenWithdrawUSDC] = useState(false)
const [opponents, setOpponents] = useState([])
const [selectedOpponent, setSelectedOpponent] = useState([]);
const [battleData, setBattleData] = useState([]);
const [cancelOpponent, setCancelOpponent] = useState();
const [filter, setFilter] = useState(''); // New state for filtering input
const [challenges, setChallenges] = useState([
    { id: 0, attacker: '', defender: '', bet: [] }
  ]);

const { isMuted } = useSound();
const store = useSelector((state) => state);
const dispatch = useDispatch();
const { setError } = useError();

const fetchData = useCallback(async () => {
    if (ual.activeUser && store)
    {
        const values = await anchorGetUsdcFromWallet(ual)
        setUsdcWallet(parseFloat(values[0].value).toFixed(3))
        const values1 = await anchorGetUsdcFromPvp(ual)
        setUsdcAmount(parseFloat(values1).toFixed(3))
        const allEntries = await anchorGetPvPOpponents(ual)
        setOpponents(allEntries)
        const allChallenges = await anchorGetChallengesInitiated(ual)
        setChallenges(allChallenges)
        const allBattles = await anchorGetAllBattleStats(ual)
        setBattleData(allBattles)
    }
}, [ual, store]); 

  useEffect(() => {
    fetchData();
  }, [store, ual]);

  async function cancelChallenge(id) {
    await anchorCancelPvpChallenge(ual, id, setError)
    if (!isMuted) {
        const audio = new Audio(soundFile);
        audio.play().catch((error) => {
        console.error('Audio playback failed:', error);
        });
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  return (    
<div className="lg:w-1/4">
    <div className="flex flex-wrap justify-center gap-x-4 gap-y-2 mt-4">
        
        <div className="flex flex-col items-center">
            <div className="flex items-center space-x-2 mb-2">
                <span className="text-lg font-bold text-white">{usdcWallet}</span> 
                <img src={USDCIcon} alt="Add Icon" className="h-6 w-6" />
            </div>
            <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => setIsOpenAddUSDC(true)}>
                Add USDC
            </button>
            <AddUSDCPopup isOpen={isOpenAddUSDC} setIsOpen={setIsOpenAddUSDC} ual={ual} />
        </div>

        <div className="flex flex-col items-center">
            <div className="flex items-center space-x-2 mb-2">
                <span className="text-lg font-bold text-white">{usdcAmount}</span>
                <img src={USDCIcon} alt="Withdraw Icon" className="h-6 w-6" />
            </div>
            <button className="buttonContainer bg-[#2F2F2E] bg-opacity-80 text-white rounded-md px-6 py-3 w-40" onClick={() => setIsOpenWithdrawUSDC(true)}>
                Withdraw
            </button>
            <WithdrawUsdcPopup isOpen={isOpenWithdrawUSDC} setIsOpen={setIsOpenWithdrawUSDC} ual={ual} />
        </div>

        
        <div className="w-80 h-80 overflow-y-scroll mt-4 bg-[#2F2F2E] bg-opacity-80 rounded-md p-2">
        <span className="text-white text-xl text-center font-extrabold shadow-lg mb-2 block">Opponents</span>

        <input
            type="text"
            placeholder="Filter opponents"
            className="w-full mb-2 p-1 rounded-md"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
        />

        {[
            ...(filter === ''
            ? opponents.filter(playerName => playerName === 'global')
            : []),
            ...opponents.filter(playerName =>
            playerName !== 'global' &&
            playerName.toLowerCase().startsWith(filter.toLowerCase())
            ),
        ].map((playerName, index) => {
            // Find player data from the list based on the player name, excluding "global"
            const playerInfo = playerName !== 'global' ? battleData.find(player => player.user === playerName) : null;
            const wins = playerInfo ? playerInfo.challenges_won : 0;
            const loss = playerInfo ? playerInfo.challenges_lost : 0;

            return (
            <div
                key={index}
                className="flex items-center justify-between p-2 text-white border-b border-gray-600 last:border-b-0"
            >
                <span className="text-white">
                {playerName === 'global' ? 'Global Challenge' : `${playerName} W: ${wins} L:${loss}` }
                </span>
                <button
                className="bg-[#25a11a] bg-opacity-80 text-white rounded-md px-3 py-1 w-24 text-sm"
                onClick={() => {
                    setIsOpenChallenge(true);
                    setSelectedOpponent(playerName);
                }}
                >
                Challenge
                </button>
                <ChallengePopup
                isOpen={isOpenChallenge}
                setIsOpen={setIsOpenChallenge}
                ual={ual}
                opponent={selectedOpponent}
                />
            </div>
            );
        })}
        </div>


        <div className="w-80 h-40 overflow-y-scroll mt-4 bg-[#2F2F2E] bg-opacity-80 rounded-md p-2">
        <span className="text-white text-xl text-center font-extrabold shadow-lg mb-2 block">Challenges sent</span>
        {challenges.map(({ id, attacker, defender, bet }, index) => (
            <div key={id || index} className="flex items-center justify-between p-2 text-white border-b border-gray-600 last:border-b-0">
            
            <span className="text-white">{defender}</span>
            
            <div className="flex flex-col space-y-1 mr-4">
                {bet.map((num, i) => (
                <div key={i} className="flex items-center space-x-1">
                    <span className="text-sm text-white">{parseFloat(num).toFixed(2)}</span>
                    <img src={[CCIcon, DCIcon, HTIcon, USDCIcon][i]} 
                        alt="icon" className="h-6 w-6" />
                </div>
                ))}
            </div>

            <button
                className="bg-[#ad1111] bg-opacity-80 text-white rounded-md px-3 py-1 w-24 text-sm"
                onClick={() => 
                    {
                    cancelChallenge(id)
                    }
                }
            >
                Cancel Challenge
            </button>
            </div>
        ))}
        </div>
    </div>
</div>
);
};



export default MiddleContent;
