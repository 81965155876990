import React, { useState, useEffect, useCallback, useMemo, Component } from "react";
import { FaTimes } from "react-icons/fa";
import USDCIcon from "assets/images/icons/usdc.png";
import Modal from "components/shared/Modal";
import { useError } from 'ErrorProvider';

import { useDispatch, useSelector } from "react-redux";
import {anchorGetUsdcFromWallet, anchorGetUsdcFromPvp, refreshAll, donateTournament, createTournament} from "BlockchainInteractionWax.js"

const CreateTournamentPopup = ({ isOpen, setIsOpen, ual }) => {
  const [usdcWallet, setUsdcWallet] = useState(0);
  const [usdcAmount, setUsdcAmount] = useState("0.00");
  const [players, setPlayers] = useState("0");
  const [tour_name, setTourName] = useState("");
  const [maxLevel, setMaxLevel] = useState("0");

  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  const fetchData = useCallback(async () => {
    if (ual.activeUser && store) {
      const values = await anchorGetUsdcFromPvp(ual)
      setUsdcWallet(parseFloat(values).toFixed(3))
    }
  }, [ual, store]); 

  useEffect(() => {
    fetchData();
  }, [fetchData, store]);

  async function handleCreateTournament()
  {
    await createTournament(ual, usdcAmount, players, maxLevel, tour_name, setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch)
    setIsOpen(false);    
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
      <div
        className="w-full rounded-xl py-4 px-6 font-Montserrat py-4 px-6"
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <form>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-white">
              Create Tournament
            </h2>
            <FaTimes
              className="block text-2xl text-white hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="text-center text-white"></div>
          <div>
            <div className=" flex items-center justify-end gap-x-2 text-white">              
            </div>
            <div className=" flex items-center justify-end gap-x-2 text-white">              
              <p className="mt-2">Available USDC {parseFloat(usdcWallet).toFixed(3)}</p>
              <div className="h-8 w-12">
                <img
                  src={USDCIcon}
                  alt="icon"
                  className="object- h-full w-auto"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 justify-center">
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Entry fee:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={usdcAmount}
                  onChange={(event) => setUsdcAmount(event.target.value)}
                  placeholder="Enter amount to deposit"
                  step="0.01"
                  min="0"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Size(power of 2):</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={players}
                  onChange={(event) => setPlayers(event.target.value)}
                  placeholder="Enter amount to deposit"
                  step="1"
                  min="0"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Name:</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="string"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={tour_name}
                  onChange={(event) => setTourName(event.target.value)}
                  placeholder="Enter name"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <label className="text-white h-10">Max level: 0-99</label>
            </div>
            <div className="flex flex-col justify-center flex-1 ml-2">
              <div className="flex flex-row items-center">
                <input
                  type="number"
                  className="rounded-lg border border-gray-400 p-2 w-full sm:w-48 h-10 mb-2"

                  value={maxLevel}
                  onChange={(event) => setMaxLevel(event.target.value)}
                  placeholder="Enter amount to deposit"
                  step="1"
                  min="0"
                />
              </div>
            </div>
          </div>
        </div>

          <div className="mt-5 flex justify-center">
          <div className='buttonContainer'>
            <button className="w-40 py-1 px-3"
            onClick={(e) => {e.preventDefault(); handleCreateTournament()}}>Pay 1.5 USDC</button>
            /</div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateTournamentPopup;
