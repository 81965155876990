import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useError } from "ErrorProvider";
import USDCIcon from "assets/images/icons/usdc.png";
import DonateUSDCPopup from "./DonateUSDCPopup"
import CreateTournamentPopup from "./CreateTournamentPopup"
import ResultsPopUp  from "../PvP/ResultsPopUp";
import { useSound } from "SoundContext";
import joinT from "assets/sound/joinT.mp3";
import fight from "assets/sound/fight.mp3";
import withdrawT from "assets/sound/withdrawT.mp3";

import { anchorGetAllTournaments, anchorGetPvPTournaments, joinTournament, refreshAll, withdrawTournament, triggerFightTournament, anchorGetTournamentReports } from "BlockchainInteractionWax";

const AllContent = ({ ual }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();
  const { isMuted } = useSound();

  const [tournaments, setTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [tournamentsJoined, setTournamentsJoined] = useState([])
  const [isOpenDonateUSDC, setIsOpenDonateUSDC] = useState(false);
  const [isOpenCreateTournament, setIsOpenCreateTournament] = useState(false);
  const [results, setResults] = useState([]);
  const [isOpenResults, setIsOpenResults] = useState(false)
  const [selectedLog, setSelectedLog] = useState([]);
  const [filter, setFilter] = useState("All"); // Default filter value
  const [filteredResults, setFilteredResults] = useState([]); // Filtered results array
  const [filterT, setFilterT] = useState("all"); // Default filter is "All Tournaments"  

  // Fetch tournaments data
  const fetchData = async () => {
    if (ual.activeUser) {
      try {
        const value = await anchorGetAllTournaments(ual);
        setTournaments(value);
        const value1 = await anchorGetPvPTournaments(ual);
        setTournamentsJoined(value1)
      } catch (error) {
        setError(error.message);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [store, ual.activeUser]);

  useEffect(() => {
    if (filter === "All") {
      setFilteredResults(results); // Show all results
    } else if (filter === "User") {
      setFilteredResults(
        results.filter(
          (report) =>
            report.attacker === ual.activeUser?.accountName ||
            report.defender === ual.activeUser?.accountName
        )
      ); // Filter for user-related fights
    }
  }, [filter, results, ual.activeUser]);

  const filteredTournaments = useMemo(() => {
  switch (filterT) {
    case "open":
      return tournaments.filter((tournament) => tournament.status === 0);
    case "inProgress":
      return tournaments.filter((tournament) => tournament.status === 1);
    case "closed":
      return tournaments.filter((tournament) => tournament.status === 2);
    default:
      return tournaments; // "all"
  }
}, [filterT, tournaments]);

  async function WithdrawFromTournament(tournament) {
    await withdrawTournament(ual, tournament.tournament_id, setError)
    if (!isMuted) {
      const audio = new Audio(withdrawT);
      audio.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
    fetchData();
  }

  async function UpdateSelectedTournament(id){
  if (ual.activeUser) {
    try {
      const allResults = await anchorGetTournamentReports(ual, id)
      setResults(allResults)
    } catch (error) {
      setError(error.message);
    }
  }
}

  async function JoinTournament(tournament) {
    await joinTournament(ual, tournament.tournament_id, setError)
    if (!isMuted) {
      const audio = new Audio(joinT);
      audio.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
    fetchData();
  }

  async function triggerFight(tournament)
  {
    await triggerFightTournament(ual, tournament.tournament_id, setError)
    if (!isMuted) {
      const audio = new Audio(fight);
      audio.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
    const allResults = await anchorGetTournamentReports(ual, tournament.tournament_id)
    setResults(allResults)
    fetchData();
  }

  return (
    <div className="flex flex-wrap lg:flex-nowrap items-start lg:space-x-4 w-full">
      {/* Left: Scrollable Area for Tournaments */}
      <div className="w-full lg:w-1/3 h-[55vh] bg-[#2F2F2E] bg-opacity-80 rounded-md p-2 flex flex-col">
  <h2 className="text-white text-xl text-center font-extrabold shadow-lg mb-2">
    Tournaments
  </h2>

  {/* Filter Dropdown */}
  <div className="mb-4 flex justify-center">
  <select
    className="bg-gray-800 text-white rounded-md p-2 w-30" // Reduced width
    onChange={(e) => setFilterT(e.target.value)} // Hook for filter
  >
    <option value="all">All</option>
    <option value="open">Open</option>
    <option value="inProgress">In Progress</option>
    <option value="closed">Closed</option>
  </select>
</div>

  <div className="flex-grow overflow-y-scroll">
    {filteredTournaments.length > 0 ? (
      filteredTournaments.map((tournament) => {
        let rowColor;
        let statusLabel;
        switch (tournament.status) {
          case 0:
            rowColor = "bg-green-700";
            statusLabel = "Open";
            break;
          case 1:
            rowColor = "bg-red-700";
            statusLabel = "In Progress";
            break;
          case 2:
            rowColor = "bg-gray-700";
            statusLabel = "Finished";
            break;
          default:
            rowColor = "bg-gray-800";
            statusLabel = "Unknown";
        }

        return (
          <div
            key={tournament.tournament_id}
            className={`flex items-center justify-between p-2 text-white border-b border-gray-600 last:border-b-0 ${rowColor}`}
          >
            <span className="text-white font-semibold text-sm">
              {`T Name: ${tournament.tour_name}`}
            </span>
            <span className="text-xs text-white-500">{statusLabel}</span>
            <button
              className="bg-[#29c4cc] bg-opacity-80 text-white rounded-md px-3 py-1 w-24 text-sm"
              onClick={() => {
                setSelectedTournament(tournament);
                UpdateSelectedTournament(tournament.tournament_id);
                setFilter("All")
              }}
            >
              View
            </button>
          </div>
        );
      })
    ) : (
      <p className="text-white text-center mt-4">No tournaments found.</p>
    )}
  </div>

  <div className="mt-4 flex justify-center">
    <button
      className="bg-[#29c4cc] bg-opacity-80 hover:bg-blue-500 text-white py-2 px-6 rounded-md"
      onClick={() => setIsOpenCreateTournament(true)}
    >
      Add Tournament
    </button>
    <CreateTournamentPopup
      isOpen={isOpenCreateTournament}
      setIsOpen={setIsOpenCreateTournament}
      ual={ual}
    />
  </div>
</div>


      <div className="w-full lg:w-1/3 h-[55vh] bg-[#2F2F2E] bg-opacity-80 rounded-md p-6 flex items-center justify-center">
  {selectedTournament ? (
    <div className="text-center">
      <h2 className="text-white text-xl font-extrabold shadow-lg mb-4">
        Tournament {selectedTournament.tour_name}
      </h2>

      {/* Content for Completed Tournaments (Status = 2) */}
      {selectedTournament.status === 2 && (
        <>
          <p className="text-white mb-2 flex justify-center items-center">
            <strong className="mr-1">Total Prize:</strong>
            {parseFloat(selectedTournament.total_prize).toFixed(3) || "N/A"} 
            <img src={USDCIcon} alt="Prize Icon" className="ml-2 h-6 w-6" />
          </p>
          <p className="text-white mb-2 flex justify-center items-center">
            <strong className="mr-1">Winner:</strong>
            {selectedTournament.next_round?.[0] || "N/A"}
          </p>
        </>
      )}

      {/* Content for In-Progress Tournaments (Status = 1) */}
      {selectedTournament.status === 1 && (
        <div className="mt-4">
          <p className="text-white mb-2 flex justify-center items-center">
            <strong className="mr-1">Round:</strong>
            {selectedTournament.current_round + 1 || "N/A"}
          </p>
          <p className="text-white mb-4 flex justify-center items-center">
            <strong className="mr-1">Total Prize:</strong>
            {parseFloat(selectedTournament.total_prize).toFixed(3) || "N/A"} 
            <img src={USDCIcon} alt="Prize Icon" className="ml-2 h-6 w-6" />
          </p>
          <p className="text-white mb-2 flex justify-center items-center">
            <strong className="mr-1">Fighting:</strong>
            {selectedTournament.curr_round?.[0] || "N/A"} vs{" "}
            {selectedTournament.curr_round?.[1] || "N/A"}
          </p>
          <button
            className="buttonContainer bg-yellow-600 bg-opacity-80 text-white rounded-md px-6 py-3 w-40"
            onClick={() => triggerFight(selectedTournament)}
          >
            Next Fight
          </button>
        </div>
      )}

      {/* Content for Open Tournaments (Status = 0) */}
      {selectedTournament.status === 0 && (
        <>
          <p className="text-white mb-2 flex justify-center items-center">
            <strong className="mr-1">Tournament size:</strong>
            {selectedTournament.participants || "N/A"}
          </p>
          <p className="text-white mb-2 flex justify-center items-center">
            <strong className="mr-1">Entry Fee:</strong>
            {parseFloat(selectedTournament.entry_fee).toFixed(3) || "N/A"} 
            <img src={USDCIcon} alt="Entry Fee Icon" className="ml-2 h-6 w-6" />
          </p>
          <p className="text-white mb-4 flex justify-center items-center">
            <strong className="mr-1">Current Prize:</strong>
            {parseFloat(selectedTournament.total_prize).toFixed(3) || "N/A"} 
            <img src={USDCIcon} alt="Prize Icon" className="ml-2 h-6 w-6" />
          </p>
          <p className="text-white mb-4 flex justify-center items-center">
            <strong className="mr-1">Maximum Prize:</strong>
            {selectedTournament.total_prize -
              selectedTournament.curr_round.filter((name) => name !== "tiny")
                .length *
                selectedTournament.entry_fee +
              selectedTournament.entry_fee * selectedTournament.participants || "N/A"}
            <img src={USDCIcon} alt="Prize Icon" className="ml-2 h-6 w-6" />
          </p>
          <p className="text-white mb-4 flex justify-center items-center">
            <strong className="mr-1">Spots Left:</strong>
            {selectedTournament.curr_round
              ? selectedTournament.curr_round.filter((name) => name === "tiny")
                  .length
              : 0}
          </p>
          <p className="text-white mb-2 flex justify-center items-center">
            <strong className="mr-1">Maximum level:</strong>
            {selectedTournament.type || "0"}
          </p>
          {tournamentsJoined.includes(parseInt(selectedTournament.tournament_id)) ? (
            <div className="mt-4">
              <button
                className="buttonContainer bg-red-700 bg-opacity-80 text-white rounded-md px-6 py-3 w-40"
                onClick={() => WithdrawFromTournament(selectedTournament)}
              >
                Withdraw
              </button>
            </div>
          ) : (
            <div className="mt-4">
              <button
                className="buttonContainer bg-green-700 bg-opacity-80 text-white rounded-md px-6 py-3 w-40"
                onClick={() => JoinTournament(selectedTournament)}
              >
                Join
              </button>
            </div>
          )}
          <div className="mt-4">
            <button
              className="buttonContainer bg-yellow-600 bg-opacity-80 text-white rounded-md px-6 py-3 w-40"
              onClick={() => setIsOpenDonateUSDC(true)}
            >
              Donate
            </button>
            <DonateUSDCPopup
              isOpen={isOpenDonateUSDC}
              setIsOpen={setIsOpenDonateUSDC}
              ual={ual}
              tournament={selectedTournament.tournament_id}
            />
          </div>
        </>
      )}
    </div>
  ) : (
    <p className="text-white text-center">Select a tournament to see details.</p>
  )}
</div>

<div className="w-full lg:w-1/3 h-[55vh] bg-[#2F2F2E] bg-opacity-80 rounded-md p-4 sm:mt-4 lg:mt-0 flex flex-col">
  <span className="text-white text-xl text-center font-extrabold shadow-lg mb-2 block">Reports</span>

  {/* Filter Dropdown */}
  <div className="mb-2 flex justify-center">
    <select
      className="bg-[#1a1a1a] text-white rounded-md p-2"
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
    >
      <option value="All">All</option>
      <option value="User">User</option>
      {selectedTournament !== null && selectedTournament.status !== undefined && selectedTournament.status === 2 && ( <option value="Rankings">Rankings</option> )}
    </select>
  </div>

  {/* Conditionally Render Based on Filter */}
  <div className="flex-grow overflow-y-scroll">
    {filter === "Rankings" ? (
      <div className="text-white p-4">
        <h3 className="text-lg font-extrabold mb-4">Rankings</h3>
        {selectedTournament !== null && selectedTournament.rankings && selectedTournament.rankings.length > 0 ? (
          (() => {
            // Parse rankings data into rounds
            const rankingsByRound = selectedTournament.rankings.reduce((acc, entry) => {
              const roundMatch = entry.match(/Round (\d+): (.+) (lost|won)/);
              if (roundMatch) {
                const [, round, player, result] = roundMatch;
                const roundNum = parseInt(round, 10);
                if (!acc[roundNum]) acc[roundNum] = [];
                if (result === "lost" || result === "won") {
                  acc[roundNum].push({ player, result });
                }
              }
              return acc;
            }, {});

            // Generate rankings
            const sortedRounds = Object.keys(rankingsByRound)
              .map((r) => parseInt(r, 10))
              .sort((a, b) => b - a); // Sort rounds in descending order
            const finalRanking = [];
            sortedRounds.forEach((round, index) => {
              const players = rankingsByRound[round];
              if (index === 0) {
                // Winner (last round, "won")
                const winner = players.find((p) => p.result === "won");
                if (winner) finalRanking.push({ player: winner.player, rank: 1 });
              }
              // All losers in this round
              players
                .filter((p) => p.result === "lost")
                .forEach((loser) => {
                  finalRanking.push({ player: loser.player, rank: index + 1 }); // Rank starts at 2 for 2nd place
                });
            });

            return (
              <ol className="list-decimal ml-4">
                {finalRanking.map(({ player, rank }) => (
                  <li key={player} className="mb-2">
                    <span className="font-bold">{rank === 1 ? "Winner" : `Rank ${rank}`}: </span>
                    {player}
                  </li>
                ))}
              </ol>
            );
          })()
        ) : (
          <p className="text-white">No rankings available for this tournament.</p>
        )}
      </div>
    ) : (
      filteredResults.length > 0 ? (
        filteredResults
          .sort((a, b) => b.id - a.id) // Sort by id in descending order
          .map(({ id, attacker, defender, winner, tournament_round, event_log }, index) => {
            const isUserAttackerOrDefender =
              ual.activeUser?.accountName === attacker || ual.activeUser?.accountName === defender;

            return (
              <div
                key={`${id}-${index}`} // Combine id and index to ensure uniqueness
                className={`flex items-center justify-between p-2 text-white border-b border-gray-600 last:border-b-0 ${
                  isUserAttackerOrDefender
                    ? winner === ual.activeUser?.accountName
                      ? 'bg-green-700' // User won
                      : 'bg-red-700'   // User lost
                      : 'bg-purple-500'    // User not involved
                }`}
              >
                <span className="text-white text-sm">
                  {attacker} vs {defender} Round: {tournament_round + 1}
                </span>

                <button
                  className="bg-[#29c4cc] bg-opacity-80 text-white rounded-md px-3 py-1 w-24 text-sm"
                  onClick={() => {
                    setSelectedLog(event_log); // Set the selected event log
                    setIsOpenResults(true);    // Open the results popup
                  }}
                >
                  Results
                </button>
              </div>
            );
          })
      ) : (
        <p className="text-center text-white mt-4">No fights in the tournament yet</p>
      )
    )}
  </div>

  <ResultsPopUp isOpen={isOpenResults} setIsOpen={setIsOpenResults} ual={ual} log={selectedLog} />
</div>


    </div>
  );
};

export default AllContent;
