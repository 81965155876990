import React, { useEffect, useState, useCallback } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import WaxIcon from "assets/images/icons/waxp.png";
import USDCIcon from "assets/images/icons/usdc.png";
import CCIcon from "assets/images/icons/clean-cash2.png"
import DCIcon from "assets/images/icons/dirty-cash-token.png"
import HTIcon from "assets/images/icons/hustlers-token.png"
import { useError } from 'ErrorProvider';
import { useSound } from 'SoundContext';
import soundFile from 'assets/sound/accept_battle.mp3'

import {refreshAll, anchorGetChallengesReceived, acceptPvpChallenge, refusePvpChallenge, anchorGetResourcesBattled} from "BlockchainInteractionWax";

const RightContent = ({ ual }) => {

  const [challenges, setChallenges] = useState([
    { id: 0, attacker: '', defender: '', bet: [] }
  ]);
  const [resources, setResources] = useState([
    { challenges_won: 0, challenges_lost: 0, resources_won: [0,0,0,0], resources_lost: [0,0,0,0] } ]);

  const { isMuted } = useSound();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  const fetchData = useCallback(async () => {
    if (ual.activeUser && store)
    {
        const allChallenges = await anchorGetChallengesReceived(ual)
        setChallenges(allChallenges)
        const resourcesBattled = await anchorGetResourcesBattled(ual)
        if(resourcesBattled !== undefined)
          {
            setResources(resourcesBattled)
          }  
    }
}, [ual, store]); 

  useEffect(() => {
    fetchData();
  }, [store, ual]);

  async function acceptChallenge(id) {

    await acceptPvpChallenge(ual, id, setError)
    if (!isMuted) {
      const audio = new Audio(soundFile);
      audio.play().catch((error) => {
        console.error('Audio playback failed:', error);
      });
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  async function refuseChallenge(id) {

    await refusePvpChallenge(ual, id, setError)
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  return (
    <div className="lg:w-1/4 h-[50vh]">
  <div className="flex flex-wrap justify-center gap-x-4 gap-y-2 mt-4 h-full">
    {/* Original scrollable section */}
    <div className="w-80 h-70 overflow-y-scroll mt-4 bg-[#2F2F2E] bg-opacity-80 rounded-md p-2">
      <span className="text-white text-xl text-center font-extrabold shadow-lg mb-2 block">Challenges received</span>
      {challenges.map(({ id, attacker, defender, bet }, index) => (
        <div key={id || index} className="flex items-center justify-between p-2 text-white border-b border-gray-600 last:border-b-0">
          <span className={`text-sm ${defender === 'global' ? 'text-green-500' : 'text-white'}`}>
            {attacker}
          </span>
          
          <div className="flex flex-col space-y-1 mr-4">
            {bet.map((num, i) => (
              <div key={i} className="flex items-center space-x-1">
                <span className="text-sm text-white">{parseFloat(num).toFixed(2)}</span>
                <img src={[CCIcon, DCIcon, HTIcon, WaxIcon, USDCIcon][i]} alt="icon" className="h-6 w-6" />
              </div>
            ))}
          </div>

          <div className="flex flex-col space-y-2">
            <button
              className="bg-[#25a11a] bg-opacity-80 text-white rounded-md px-3 py-1 w-24 text-sm"
              onClick={() => acceptChallenge(id)}
            >
              Battle
            </button>
            <button
              className="bg-[#ad1111] bg-opacity-80 text-white rounded-md px-3 py-1 w-24 text-sm mt-2"
              onClick={() => refuseChallenge(id)}
            >
              Refuse
            </button>
          </div>
        </div>
      ))}
    </div>

    <div className="w-80 h-100 mt-4 bg-[#2F2F2E] bg-opacity-80 rounded-md p-2">
  <h3 className="text-white text-xl text-center font-extrabold shadow-lg mb-4">Statistics</h3>

      {/* Resource Won */}
      <div className="mb-4">
        <h3 className="text-white text-mg font-semibold mb-1">Resources Won</h3>
        <div className="grid grid-cols-2 gap-2">
          {['CCIcon', 'DCIcon', 'HTIcon', 'WaxIcon', 'USDCIcon'].map((icon, i) => (
            <div key={i} className="flex items-center space-x-2">
              <img src={[CCIcon, DCIcon, HTIcon, WaxIcon, USDCIcon][i]} alt="icon" className="h-5 w-5" />
              <span className="text-sm text-white">
                {Number((resources.resources_won && resources.resources_won[i]) || 0).toFixed(2)}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Resource Lost */}
      <div className="mb-3">
        <h3 className="text-white text-mg font-semibold mb-1">Resources Lost</h3>
        <div className="grid grid-cols-2 gap-2">
          {['CCIcon', 'DCIcon', 'HTIcon', 'WaxIcon', 'USDCIcon'].map((icon, i) => (
            <div key={i} className="flex items-center space-x-2">
              <img src={[CCIcon, DCIcon, HTIcon, WaxIcon, USDCIcon][i]} alt="icon" className="h-5 w-5" />
              <span className="text-sm text-white">
                {Number((resources.resources_lost && resources.resources_lost[i]) || 0).toFixed(2)}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Battles Won */}
      <div className="mb-3">
        <h3 className="text-white text-mg font-semibold mb-1">Battles Won: {resources.challenges_won || 0}</h3>
      </div>

      {/* Battles Lost */}
      <div className="mb-3">
        <h3 className="text-white text-mg font-semibold mb-1">Battles Lost: {resources.challenges_lost || 0}</h3>
      </div>
    </div>
  </div>
</div>

  );
};



export default RightContent;
