import React, { useState, useEffect, useCallback } from "react";
import icon1 from "assets/images/gitbook.png";
import banner from "assets/images/home/banner.png";
import { registerAccount, refreshAll, getIsAccountCreated } from "BlockchainInteractionWax";
import { useDispatch, useSelector } from "react-redux";
import { useError } from 'ErrorProvider';

const Hero = ({ ual }) => {
  const [newUser, setNewUser] = useState(false);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();
  const [showComingSoon, setShowComingSoon] = useState(false);

  const fetchData = useCallback(async () => {
    if (ual) {
      const userCreated = await getIsAccountCreated(ual);
      setNewUser(userCreated);
    }
  }, [ual, store]);

  async function registerUser() {
    await registerAccount(ual, setError);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    refreshAll(dispatch);
  }

  const openLoginModal = () => {
    if (!ual.activeUser) {
      ual.showModal();
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div
      className="hero-section font-Montserrat flex min-h-screen items-center justify-center bg-black bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${banner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: "-80px",
      }}
    >
      <div className="mx-3 pt-10 text-center text-white md:mx-auto md:w-2/12">
        
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <h2 className="text-5xl font-bold capitalize text-primary font-Montserrat mt-3">
          The Hustle
        </h2>
        <div className="flex flex-wrap justify-center">
          <p className="mt-4 text-2xl mx-1">"Collect - Hustle - Battle" </p>
        </div>
        <div className="mt-6">
          <p className="text-xl font-bold">Check out our whitepaper</p>
          <div className="mt-4 flex justify-center">
            <a
              href="https://thehustlegame.gitbook.io/whitepaper/"
              target={"_blank"}
              className="block h-10 w-10"
              rel="noreferrer"
            >
              <img
                src={icon1}
                alt="Whitepaper Icon"
                className="h-full w-full object-contain"
              />
            </a>
          </div>
        </div>
        {newUser === false && ual.activeUser && (
          <div className="buttonContainer">
            <button className="w-full px-4 py-1" onClick={registerUser}>
              Join for Free
            </button>
          </div>
        )}
         {!ual.activeUser && (
          <div className="flex justify-between w-full">
            <div className="buttonContainer">
              <button className="px-4 py-1" onClick={openLoginModal}>
                Login WAX
              </button>
            </div>
            <div className="buttonContainer">
              <button className="px-4 py-1" onClick={() => setShowComingSoon(true)}>
                Login Ronin
              </button>
            </div>
          </div>
        )}
        {/* Modal */}
        {showComingSoon && (
        <div className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex items-center justify-center z-50 transition-opacity duration-300">
          <div className="bg-gradient-to-br from-purple-800 to-blue-800 bg-opacity-80 text-white p-6 rounded-2xl shadow-2xl border border-white/20 w-80 text-center">
            <h2 className="text-2xl font-bold mb-4">🚧 Coming Soon 🚧</h2>
            <p className="mb-6">Ronin game under construction. Stay tuned!</p>
            <button
              className="px-4 py-2 bg-white text-purple-800 font-semibold rounded hover:bg-purple-100 transition"
              onClick={() => setShowComingSoon(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      </div>
    </div>
  );
  
};

export default Hero;
