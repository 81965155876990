import React, { Component } from "react";
import Wax from "assets/images/icons/waxp.png";
import WUFFI from "assets/images/icons/wuf_wuffi.png";
import DM from "assets/images/icons/dungeon_master.svg";

class Footer extends Component {
  render() {
    const icons = [Wax, DM, WUFFI]; // Define icons once to avoid repetition

    return (
      <footer className="mx-auto w-full py-10 relative overflow-hidden bg-gray-900">
        {/* PARTNERS Text at the Top */}
        <div className="text-center mb-6">
          <p className="text-2xl text-white ">PARTNERS</p>
        </div>

        {/* Animated Icons */}
        <div className="relative w-full h-16 sm:h-20 md:h-24 overflow-hidden">
          <div className="flex space-x-10 sm:space-x-14 md:space-x-16 animate-marquee">
            {/* Double icons to prevent gaps */}
            {[...Array(10)].flatMap(() =>
              icons.map((icon, index) => (
                <img
                  key={index + Math.random()}
                  src={icon}
                  alt={`Partner ${index + 1}`}
                  className="w-14 sm:w-16 md:w-20 h-auto min-w-[50px] flex-shrink-0 opacity-80"
                />
              ))
            )}
          </div>
        </div>

        {/* Footer Content */}
        <div className="text-center mt-14">
          <p className="text-2xl text-white">
            Copyright © 2025 Nambua Games LTD. All rights reserved.
          </p>
          <p className="py-5 text-white opacity-50 max-w-3xl mx-auto">
            Nambua Games LTD, company number 14518393, headquartered in
            Birmingham, UK, is registered with Companies House, an executive
            agency sponsored by the Department for Business, Energy & Industrial
            Strategy.
          </p>
        </div>

        <div className="text-center">
          <a
            href="/terms"
            className="font-medium text-white hover:underline opacity-70"
          >
            T&Cs
          </a>
          <a
            href="/privacy"
            className="font-medium text-white hover:underline ml-10 opacity-70"
          >
            Privacy Policy
          </a>
        </div>

        {/* Animation CSS */}
        <style>
          {`
            @keyframes marquee {
              0% { transform: translateX(100%); }
              100% { transform: translateX(-100%); }
            }

            .animate-marquee {
              display: flex;
              white-space: nowrap;
              animation: marquee 20s linear infinite;
            }
          `}
        </style>
      </footer>
    );
  }
}

export default Footer;
