import Tippy from "@tippyjs/react";
import React, { useState, useEffect, useCallback, useRef, Component } from "react";
import ForeignExchangeImage from "assets/images/transfers/foreign-exchange.png";
import { FaInfoCircle } from "react-icons/fa";
import AttemptExchange from "./forms/AttemptExchange";
import AttemptDeposit from "./forms/AttemptDeposit"
import { useDispatch, useSelector } from "react-redux";
import AttemptWithdraw from "./forms/AttemptWithdraw";
import "../Hustle/index.css"
import 'tippy.js/dist/tippy.css';
import "@waxonedge/swap";

import {refreshAll, anchorGetGRank, anchorGetHustlerTokenWithdraws, anchorGetExchangeCd} from "BlockchainInteractionWax.js"

const ForeignExchange = ({ ual }) => {
  const store = useSelector((state) => state);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWithdraw, setIsOpenWithdraw] = useState(false);
  const [isOpenExchange, setIsOpenExchange] = useState(false);
  const [timeToExchangeMsg, setTimeToExchangeMsg] = useState("")
  const [timeToEvent, setTimeToEvent] = useState("")
  const [gLevel, setGLevel] = useState(0.0);
  const [hustlerTokenWithdraws, setHustlerTokenWithdraws] = useState(0)
  const [exchange_cd, setExchangeCd] = useState(0);

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    if (ual.activeUser) {
      const gRank = await anchorGetGRank(ual);
      setGLevel(gRank)
      const day = Math.floor(((Math.floor(Date.now() / 1000) - 1711476000) / 86400) % 7);     
      
      const cd = await anchorGetExchangeCd(ual)
      setExchangeCd(cd)

      if (day === 3 || day === 4 || day === 5 || day === 6) {
        setTimeToExchangeMsg("Exchanging is not available at the moment!");
        const secondsUntilExchange = 7 - ((Math.floor(Date.now() / 1000) - 1711476000) / 86400) % 7;
        setTimeToEvent(Math.floor(secondsUntilExchange * 86400))
      }
      else {
        if (gRank >= 25) {
          setTimeToExchangeMsg("You can exchange now!");
          const secondsUntilEnd = 3 - ((Math.floor(Date.now() / 1000) - 1711476000) / 86400) % 7;
          setTimeToEvent(Math.floor(secondsUntilEnd * 86400))
        }
        else {
          setTimeToExchangeMsg("You can exchange now, but you have just " + Number(gRank).toFixed(1).toString() + " G Rank!");
          const secondsUntilEnd = 3 - ((Math.floor(Date.now() / 1000) - 1711476000) / 86400) % 7;
          setTimeToEvent(Math.floor(secondsUntilEnd * 86400))
        }
      }
      const hustlerWithdraws = await anchorGetHustlerTokenWithdraws(ual);
      setHustlerTokenWithdraws(hustlerWithdraws)

    }
  }, [store, ual]);

  useEffect(() => {
    const swapComponent = document.querySelector("waxonedge-swap");
  
    // Handle user requesting a transaction to be signed
    swapComponent.addEventListener("sign", async ({ detail }) => {
      if (!ual?.activeUser) {
        console.error("User is not logged in!");
        return;
      }
  
      try {
        swapComponent.setAttribute("signing", "true"); // Show signing state
  
        const actions = detail[0]; // Get actions to sign
  
        // Sign the transaction using UAL
        const result = await ual.activeUser.signTransaction(
          { actions },
          { blocksBehind: 3, expireSeconds: 120 } // EOSIO recommended settings
        );
  
        console.log("Swap Transaction Result:", result);
  
        swapComponent.removeAttribute("signing"); // Remove signing state
      } catch (error) {
        console.error("Swap transaction failed:", error);
        swapComponent.removeAttribute("signing");
      }
    });
  
    return () => {
      swapComponent.removeEventListener("sign", () => {});
    };
  }, [ual]);
  

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let interval;
    if (exchange_cd > 0) {
      interval = setInterval(() => {
        setExchangeCd(prevCd => prevCd - 1);
      }, 1000);
    } else if (exchange_cd <= 0 && interval) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [exchange_cd]);

  const prevTimeRef = useRef();

  useEffect(() => {
    prevTimeRef.current = timeToEvent;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToEvent(prevTime => {
        const currentTime = prevTime - 1;
        if (prevTime === 0) {
          refreshAll(dispatch);
        }
        prevTimeRef.current = currentTime;
        return currentTime;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const swapComponent = document.querySelector("waxonedge-swap");
  
    // Handle wallet connect
    swapComponent.addEventListener("connect", () => {
      console.log("User wants to connect wallet");
    });
  
    // Handle sign event (triggered when user wants to swap tokens)
    swapComponent.addEventListener("sign", ({ detail }) => {
      swapComponent.setAttribute("signing", "true"); // Show loading state
  
      const actions = detail[0];
      console.log("Transaction to sign:", actions);
  
      // Implement signing logic here...
  
      swapComponent.removeAttribute("signing"); // Hide loading state
    });
  
    return () => {
      swapComponent.removeEventListener("connect", () => {});
      swapComponent.removeEventListener("sign", () => {});
    };
  }, []);
  

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days}D ${hours % 24}h:${minutes % 60 < 10 ? `0${minutes % 60}` : minutes % 60}m:${seconds < 10 ? `0${seconds}` : seconds}s`;
    } else if (hours > 0) {
      return `${hours}h:${minutes % 60 < 10 ? `0${minutes % 60}` : minutes % 60}m:${seconds < 10 ? `0${seconds}` : seconds}s`;
    }

    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className="flex flex-wrap justify-center md:justify-between items-start px-10 py-5 gap-8 w-full">
      
      {/* Left Section: Foreign Exchange Content */}
      <div className="md:flex-1 md:w-[60%] lg:w-[65%] xl:w-[70%] min-w-[350px] max-w-[800px]">
        <div className="relative flex flex-col items-center justify-center gap-y-5 rounded-3xl px-10 pt-10 pb-3 border border-[#2F2F2E] bg-[#272424] opacity-75">
          <div className="absolute -top-8">
            <TooltipForeignExchange />
          </div>
          <div className="text-2xl font-bold text-white">Foreign Exchange</div>
          <img className="w-40" src={ForeignExchangeImage} alt="hustle" />
          <div className="mt-2 w-12"></div>
          <div className="text-center">
            <p className="pt-5 text-white">{timeToExchangeMsg}</p>
            <span className="text-white text-xl font-bold">
              {formatTime(timeToEvent)}
            </span>
          </div>
        </div>
  
        {/* Buttons for Withdraw, Deposit, and Exchange */}
        <div className="relative mt-4 flex justify-center gap-x-6 px-5 flex-wrap">
          {/* Withdraw Button */}
          <div className="relative flex justify-center">
            <div className="absolute -top-1">
              <TooltipWithdrawBtn />
            </div>
            <div className="buttonContainer my-5 mx-3">
              <button className="w-full px-3 py-1" onClick={() => setIsOpenWithdraw(true)}>
                Withdraw
              </button>
            </div>
            <AttemptWithdraw setIsOpenWithdraw={setIsOpenWithdraw} isOpenWithdraw={isOpenWithdraw} ual={ual} />
          </div>
  
          {/* Deposit Button */}
          <div className="relative flex justify-center">
            <div className="absolute -top-1">
              <TooltipDepositBtn />
            </div>
            <div className="buttonContainer my-5 mx-3">
              <button className="w-full px-3 py-1" onClick={() => setIsOpen(true)}>
                Deposit
              </button>
            </div>
            <AttemptDeposit setIsOpen={setIsOpen} isOpen={isOpen} ual={ual} />
          </div>
  
          {/* Exchange Button (If conditions allow) */}
          {timeToExchangeMsg.includes("can exchange now") && gLevel >= 25 && (
            <div className="relative flex justify-center">
              <div className="absolute -top-1">
                <TooltipExchangeBtn />
              </div>
              {exchange_cd === 0 ? (
                <div className="buttonContainer my-5 mx-3">
                  <button className="w-full px-3 py-1" onClick={() => setIsOpenExchange(true)}>
                    Exchange
                  </button>
                </div>
              ) : (
                <span className="text-white text-xl font-bold flex flex-col items-center justify-center">
                  {formatTime(exchange_cd)}
                </span>
              )}
              <AttemptExchange setIsOpenExchange={setIsOpenExchange} isOpenExchange={isOpenExchange} ual={ual} />
            </div>
          )}
        </div>
  
        {/* Display Total Hustler Tokens Withdraws */}
        <div className="relative mt-5 flex justify-center">
          <p className="pt-5 text-xl font-bold text-white">
            Total Hustler Tokens Withdraws: {hustlerTokenWithdraws}
          </p>
        </div>
      </div>
  
      {/* Right Section: WaxOnEdge Swap Component */}
      <div className="flex justify-center md:justify-end md:w-[35%] lg:w-[30%] xl:w-[25%] min-w-[300px] max-w-[400px]">
        <waxonedge-swap 
          theme="dark" 
          chart="true"
          wallet={ual?.activeUser ? `{"accountName":"${ual.activeUser.accountName}", "permission":"active"}` : undefined}>
        </waxonedge-swap>
      </div>
  
    </div>
  );  
};

class TooltipForeignExchange extends Component {
  render() {
    return (
      <Tippy
        content={
          <span className="">
            Clean Cash and Hustlers Token can be withdrawn, deposited or
            exchanged via the Foreign Exchange.
          </span>
        }
        popperOptions={{
          placement: "right",
        }}
      >
        <p className="text-xl text-white">
          <FaInfoCircle />
        </p>
      </Tippy>
    );
  }
}

class TooltipDepositBtn extends Component {
  render() {
    return (
      <Tippy
        content={
          <span className="">Deposits are not subjected to a fee</span>
        }
        popperOptions={{
          placement: "right",
        }}
      >
        <p className="text-xl text-white">
          <FaInfoCircle />
        </p>
      </Tippy>
    );
  }
}

class TooltipWithdrawBtn extends Component {
  render() {
    return (
      <Tippy
        content={
          <span className="">Withdraws are subjected to a 15% fee. You can withdraw a minimum of 60 HTN, 5 times per day. HCC can be withdrawn freely, but with a minimum of 100 HCC each time.</span>
        }
        popperOptions={{
          placement: "right",
        }}
      >
        <p className="text-xl text-white">
          <FaInfoCircle />
        </p>
      </Tippy>
    );
  }
}

class TooltipExchangeBtn extends Component {
  render() {
    return (
      <Tippy
        content={
          <span className="">
            Players can exchange a maximum of 10 Clean Cash every day tax
            free at G Rank 25
          </span>
        }
        popperOptions={{
          placement: "right",
        }}
      >
        <p className="text-xl text-white">
          <FaInfoCircle />
        </p>
      </Tippy>
    );
  }
}

export default ForeignExchange;
