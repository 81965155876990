import React, { Component } from "react";
import VideoLondon from "assets/video/welcome-to-london.mp4"; // Import your MP4 video

class WtLondon extends Component {
  render() {
    return (
      <div className="flex w-full flex-col items-center justify-center py-16 px-5 md:flex-row md:px-10 md:py-12">
        {/* Video Section */}
        <div className="w-full md:w-1/2 lg:mx-12">
          <video
            src={VideoLondon}
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>

        {/* Text Section */}
        <div className="flex w-full flex-col gap-y-5 md:w-1/2 md:p-5">
          <h2 className="text-3xl mx-8 md:mx-0 font-bold text-primary md:text-5xl font-Montserrat">
            Welcome To The Hustle
          </h2>
          <p className="text-md mx-8 md:mx-0 leading-7 text-white md:text-xl md:leading-6 lg:leading-7 xl:leading-10">
            A strategic idle RPG on the WAX blockchain. Build your empire on
            WAX, swap $HDC for WAXUSDC, and take your rewards to Solana,
            Ethereum, and more using the WAX Cloud Wallet token bridge. With the
            upcoming expansion to the Ronin Network ecosystem, the Hustle
            Universe is yours for the taking. Do you have what it takes to be
            the top hustler?
          </p>
        </div>
      </div>
    );
  }
}

export default WtLondon;
